import Api from './Api';

export function listPackages() {
  const exec = `
    query {
      packages {
        id
        firstCheckin
        lastCheckin
        total
        status
        createdAt
        user {
          id
          fullname
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec)
  };
}

export function showPackage(id) {
  const exec = `
    query($id: ID!) {
      package(id: $id) {
        id
        total
        usedCredits
        totalDiscounted
        totalIncreased
        totalIncreasedDiscounted
        status
        createdAt
        rejected
        rejectCode
        totalRefunded
        refundable
        agent {
          email
        }
        user {
          id
          fullname
        }
        payment {
          id
          totalPaid
          paymentMethod
          installment
          tid
          analysis
          processed
          paid
          cancelled
        }
        orders {
          id
          checkin
          total
          complete
          cancelled
          approved
          product {
            id
            name
          }
        }
        refunds {
          id
          amount
          interest
          credits
          ordersIds
          generateCredit
          createdAt
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}

export function approvePackage(id) {
  const exec = `
    mutation($id: ID!) {
      approvePackage(id: $id) {
        id
        approved
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}

export function assignPackage(id, email) {
  const exec = `
    mutation($id: ID!, $email: String!) {
      assignPackage(id: $id, email: $email) {
        id
        agent {
          email
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id, email })
  };
}

export function rejectPackage(id) {
  const exec = `
    mutation($id: ID!) {
      rejectPackage(id: $id)
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}
